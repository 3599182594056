.release-preview-card {
  height: 100%;
}
.release-preview-card__content {
  display: flex;
  flex-direction: column;
}

.text-grey-card {
  color: #cfcfcf;
}

.custom-color-link {
  color: #e2c3ef !important;
}

.custom-link-footer {
  color: #e2c3ef;
  transition: all 0.2s ease-out;
  font-family: "RobotoCondensed-Regular", sans-serif;
  letter-spacing: 0.06em;
  border: NONE;
  background: none;
}
.custom-link-footer:hover {
  text-decoration: none;
  color: #CF6DAE;
}

.dotted {
  border-bottom: 1px dashed #CF6DAE;
}
.dotted:hover {
  border-color: transparent;
}

.jwplayer .jw-preview.jw-reset {
  background-size: cover !important;
}

.release-preview-card__img, .media-card__wrap, .interview-item__media {
  position: relative;
}

.music-wrapper .text-grey {
  color: #cfcfcf;
}
.music-wrapper .views {
  color: #cfcfcf;
}
.music-wrapper .custom-link, .music-wrapper .footer-nav ul a {
  color: #e2c3ef;
}
.music-wrapper .custom-link:hover {
  color: #cf6dae;
}
.music-wrapper .welcome-msg {
  background: #332b45;
}
.music-wrapper .footer {
  background: #332b45;
}