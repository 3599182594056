.release-preview-card {
    height: 100%;
    &__content {
        display: flex;
        flex-direction: column;
    }
}

.text-grey-card {
    color: #cfcfcf;
}
