.custom-color-link {
    color: #e2c3ef!important;
}

.custom-link-footer {
    color: #e2c3ef;
    transition: all .2s ease-out;
    font-family: 'RobotoCondensed-Regular', sans-serif;
    letter-spacing: 0.06em;
    border: NONE;
    background: none;

    &:hover {
        text-decoration: none;
        color: #CF6DAE;
    }
}

.dotted {
    border-bottom: 1px dashed #CF6DAE;

    &:hover {
        border-color: transparent;
    }
}
