.music-wrapper {
    .text-grey {
        color: #cfcfcf
    }

    .views {
        color: #cfcfcf
    }

    .custom-link, .footer-nav ul a {
        color: #e2c3ef
    }

    .custom-link:hover {
        color: #cf6dae;
    }

    .welcome-msg {
        background: #332b45
    }

    .footer {
        background: #332b45
    }
}
